// import { Grid } from "@mui/material";
// import Center from "../components/Center";
// import Menu from "../components/Menu";
// import Partner from "../components/Partner";
// import Section from "../components/Section";

// import Sub from "./Sub";

export default function App() {
  return (
    <div style={{height:"100vh",display:"flex",justifyContent:"center", alignItems:"center"}}>
      under construction
      {/* <Menu />
      <Section bgImage="./images/background.jpeg" id="home"></Section>
      <Section bgColor="#ffffff" id="team">
        <Center>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Partner
                image="./images/fartek.jpeg"
                name="Ing. Bsc. Andreas Fartek"
                description="Allgemein gerichtlich akkredetierter und vereidigter Gerichtssachverständiger"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Partner
                image="./images/schwan.jpg"
                name="Prof. Dr. Konrad Schwan"
                description="Beratung, Training, Forschung"
              />
            </Grid>
          </Grid>
        </Center>
      </Section>
      <Section bgColor="#ffffff" id="service">
        <Center>
          <Sub />
        </Center>
      </Section>
      <Section bgColor="#ffffff" id="contact">
        <Center>
          <div>
            <h2>Andreas.fartek@svub.at</h2>
            <h3>05572 278324</h3>
          </div>
        </Center>
      </Section> */}
    </div>
  );
}
